/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import DeliveryScaleTop from '../components/deliveryatscaletop';
import Louisbester from '../assets/images/Louis-bester.jpg';
import Stephangrobler from '../assets/images/Stephan-grobler.jpg';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const deliveratscale = (props) => {
  const deliveratscaleImages = useStaticQuery(graphql`
    query deliveratscaleImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage salmon'>
      <Layout>
        <Helmet>
          <title>Urbian | Delivery at scale</title>
        </Helmet>
        <DeliveryScaleTop></DeliveryScaleTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  Your opportunity to meet a Urbian Product Owner, tap into
                  their real world experience and get insights on delivering
                  value.
                </p>
                <p>
                  Our Product Owners work on some of the most challenging
                  digital initiatives around. Financial Services, Insurance,
                  E-Commerce, Retail, SaaS, etc. They know how to solve complex
                  problems and set up teams to deliver customer and business
                  value, and can show you how.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Anyone facing challenges in scaling their digital capabilities
                  or making them more efficient/effective - doing digital at
                  scale the right way.
                </p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>Find out how to scale your digital capabilities</li>
                  <li>Hear how our experts solve global delivery challenges</li>
                  <li>
                    Discover how to reduce your lead time and maximise
                    efficiency
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={deliveratscaleImages.louis.childImageSharp.sizes}
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={deliveratscaleImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={deliveratscaleImages.anton.childImageSharp.sizes}
                      alt='Anton Moulder'
                    />
                  </div>
                  <h6>Anton Moulder</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default deliveratscale;
